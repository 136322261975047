/* HTML CORE OVERRIDES */
body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

h1 {
    color: #0072a7;
    text-transform: uppercase;
    font-size: 42px;
}

h3 {
    color: #0072a7;
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
}


h4 {
    background-color: #0072a7;
    text-align: center;
    color: #ffffff;
    padding: 5px;
}

h5, h6 {
    font-weight: bold !important;
    font-size:20px;
}

td {
    padding: 5px;
}

th {
    background-color: #f2f2f2;
    padding: 5px;
}

/* CUSTOM CLASSES */
.logoArea {
    border-bottom: 1px solid #999999;
    margin-bottom: 25px;
}

.dataRow {
    font-size: 12px;
}

.altSection1 {
    background-color: #f2f2f2;
    max-width: 1150px;
}

.gridRows {
    font-size: 80%;
}

.validation {
    color: Red;
}

.phoneNumber {
    color:#FFFFFF;
    text-align:right;
    font-size:15px;
}

#levelOneLinks a {
    color: white;
}

/* BOOTSTRAP CLASS OVERRIDES */
main > .container {
    padding: 15px;
}

.jumbotron p {
    font-size: 16px;
}

.btn {
    border-radius: 0;
    padding: 10px 20px;
    font-size: 18px;
}

.error {
    display: none;
    margin-left: 10px;
}

.error_show {
    color: red;
    margin-left: 10px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mdc-dialog__surface {
    padding: 24px !important;

    .mdc-text-field{
        padding: 0px !important;
        min-width: 250px;
    }

    .mdc-dialog__content{
        padding-top: 0px !important;
        padding-left: 6px !important;
        padding-bottom: 33px !important;
        p{
            font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
            letter-spacing: normal;
        }
    }
    .mdc-floating-label--float-above{
        padding-bottom: 15px !important;
    }

    .mdc-text-field--focused{
        background-color: white !important;
    }
    .mat-form-field-empty{
        color: #f44336 !important;
    }  

    .mat-mdc-input-element{
        min-height: 45px;
    }

    
.mat-mdc-floating-label {
    top: 45px !important;
}
    
}

.mdc-dialog__title{
    color: #0072a7 !important;
    padding: 0px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
    font-size: 14px !important;
}

.emailContainer{
    .mat-mdc-form-field{
        padding-left: 8px !important;
        .mat-focused{
            background-color: white !important;
        }
    }
    .mat-mdc-input-elemnt::placeholder{
        color: #f44336 !important;
    }  
}
